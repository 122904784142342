/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Atma';
    src: local('Atma'), url(./fonts/Atma/Atma-Medium.ttf) format('truetype');

}
@font-face {
    font-family: 'AtmaRegular';
    src: local('AtmaRegular'), url(./fonts/Atma/Atma-Regular.ttf) format('truetype');

}
@font-face {

    font-family: 'SignikaNegative';
    src: local('SignikaNegative'), url(./fonts/Signika_Negative/static/SignikaNegative-Regular.ttf) format('truetype');
}


